<template>
  <div id="login">
    <div class="h-100 bg-plum-plate-login bg-animation">
      <div class="d-flex h-100 justify-content-center align-items-center">
        <b-col md="8" class="mx-auto app-login-box">
          <div class="modal-dialog w-100 mx-auto">
            <div class="modal-content">
              <div class="modal-body">
                <div class="h5 modal-title text-center">
                  <img
                    src="@/assets/media/logos/logo.png"
                    class="w-100"
                    alt=""
                  />
                  <span class="website-text">Sign in to hellotax storage</span>
                </div>
                <form ref="form" class="p-5">
                  <b-form-group id="email-group" label-for="email" class="mb-4">
                    <b-form-input
                      id="email"
                      type="email"
                      name="email"
                      v-model="email"
                      required
                      placeholder="E-Mail"
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-group id="password-group" label-for="password">
                    <b-form-input
                      id="password"
                      type="password"
                      name="password"
                      v-model="password"
                      required
                      placeholder="Password"
                    >
                    </b-form-input>
                  </b-form-group>
                </form>
                <div class="divider" />
              </div>
              <ErrorsModal :errors="errors" />
              <div class="modal-footer clearfix">
                <div class="float-right">
                  <b-button variant="primary" @click="login" size="lg"
                    >Login</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { LOGIN } from "@/store/auth.module";
import ErrorsModal from "@/components/ErrorsModal";

export default {
  name: "Login",
  components: { ErrorsModal },
  data: () => ({
    email: "",
    password: "",
  }),
  mounted() {
    if (this.isAuthenticated) {
      this.redirectToDashboardPage();
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    login() {
      const vm = this;
      const { email, password } = vm;
      vm.$store.dispatch(LOGIN, { email, password }).then(() => {
        vm.redirectToDashboardPage();
      });
    },
    redirectToDashboardPage() {
      this.$router.push({ name: "ListUsers" });
    },
  },
};
</script>

<style scoped>
.website-text {
  text-align: center;
  display: block;
  line-height: 20px;
  font-size: 13px;
  color: #5a5a5a;
  margin-top: 11px;
  padding-bottom: 10px;
}

.form-control {
  border-width: 1px;
  border-top-color: #bdc0c7;
  box-shadow: none;
  padding: 10px 12px;
  font-size: 15px;
}
</style>
