<template>
  <div v-if="errors">
    <v-alert dense outlined type="error">
      <div v-for="(error, index) in errors" :key="index">{{ error[0] }}</div>
    </v-alert>
  </div>
</template>

<script>
export default {
  name: "ErrorsModal",
  props: ["errors"],
};
</script>

<style scoped></style>
